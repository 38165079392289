import React from 'react';
import { Link } from 'react-router-dom';

const LegalNotice = () => {
    return (
        <div className="container mx-auto px-4 py-8 text-black-lo dark:text-white-lo">
            <div className='h-8 w-full mb-5'></div>
            <h1 className="text-2xl font-bold mb-4 text-red-900">Mentions Légales</h1>
            <h2 className="text-xl font-semibold mt-4">Éditeur du site :</h2>
            <p className="mt-2">Loick Lillian Buck<br/>1430 Rebecq, Belgique<br/>Pour toute demande, veuillez vous référer à la <Link to="/contact" className="text-blue-600 hover:underline">section contact</Link> du site.</p>
            
            <h2 className="text-xl font-semibold mt-4">Hébergement :</h2>
            <p className="mt-2">O2Switch<br/>Chemin des Pardiaux, 63000 Clermont-Ferrand, France<br/>Téléphone : +33 4 44 44 60 40</p>
            
            <h2 className="text-xl font-semibold mt-4">Propriété intellectuelle :</h2>
            <p className="mt-2">Sauf mention contraire, tous les contenus présents sur le site (textes, images de projets, photographies) sont la propriété exclusive de Loick Lillian Buck. Les captures d'écran de projets et les photographies personnelles ont été créées par l'éditeur du site. Des images utilisées dans les projets peuvent provenir de sources externes et sont considérées comme étant du domaine public ou utilisées conformément à leur licence respective.</p>
            
            <h2 className="text-xl font-semibold mt-4">Conditions d'utilisation :</h2>
            <p className="mt-2">Le site "Loick Buck Portfolio" est un portfolio personnel et non commercial destiné à présenter les compétences, les projets et les expériences professionnelles de Loick Lillian Buck. L'utilisation de ce site est réservée à un usage personnel et non commercial. Il est interdit de copier, reproduire, modifier ou distribuer les contenus sans l'autorisation écrite préalable de Loick Lillian Buck. Les informations fournies sur ce site le sont à titre informatif et ne sauraient engager la responsabilité de l'éditeur.</p>
            
            <h2 className="text-xl font-semibold mt-4">Crédits photographiques :</h2>
            <p className="mt-2">Les photos personnelles utilisées sur ce site sont la propriété de Loick Lillian Buck et ne peuvent être utilisées ou reproduites sans son consentement. Les photos de projets sont des captures d'écran de projets personnels réalisés par Loick Lillian Buck.</p>
            <h2 className="text-xl font-semibold mt-4">Politique de Confidentialité :</h2>
            <p className="mt-2">
            Je, Loick Lillian Buck, m'engage à protéger la vie privée des visiteurs de mon site web. Cette politique de confidentialité explique comment je collecte, utilise, partage et protège les informations personnelles que vous fournissez.
            <br /><br />
            <strong>Collecte des Données:</strong> Je collecte uniquement les données personnelles que vous choisissez de me fournir. Ces données comprennent votre adresse e-mail et toute autre information que vous fournissez dans le cadre de demandes de contact.
            <br /><br />
            <strong>Utilisation des Données:</strong> Les données personnelles que vous fournissez sont utilisées uniquement pour répondre à vos demandes de contact. Je ne les utilise pas à des fins de marketing ou de promotion sans votre consentement explicite.
            <br /><br />
            <strong>Partage des Données:</strong> Je ne partage pas vos données personnelles avec des tiers. Vos informations restent confidentielles et sont exclusivement utilisées pour les communications entre vous et moi.
            <br /><br />
            <strong>Stockage et Sécurité des Données:</strong> Vos données sont envoyées directement à mon adresse e-mail hébergée chez Outlook.com. Je m'efforce de protéger la sécurité de vos informations personnelles, mais je ne peux garantir la sécurité totale des données transmises sur Internet.
            <br /><br />
            <strong>Cookies et Technologies de Suivi:</strong> Mon site web ne utilise pas de cookies ou d'autres technologies de suivi.
            <br /><br />
            <strong>Droits des Utilisateurs:</strong> Vous avez le droit d'accéder à vos données personnelles, de les modifier ou de demander leur suppression. Si vous souhaitez exercer ce droit, veuillez me contacter via la section contact de mon site.
            <br /><br />
            <strong>Modifications de la Politique de Confidentialité:</strong> Je me réserve le droit de modifier cette politique de confidentialité à tout moment. Toute modification sera affichée sur cette page.
            <br /><br />
            <strong>Contact:</strong> Pour toute question concernant cette politique de confidentialité ou si vous souhaitez exercer vos droits relatifs à vos données personnelles, veuillez me contacter via la section contact de mon site.
            </p>
            <h2 className="text-xl font-semibold mt-4">Clause de Responsabilité :</h2>
            <p className="mt-2">Les informations fournies sur ce site sont à titre informatif. Bien que je m'efforce d'assurer leur exactitude, je ne peux garantir l'absence d'erreurs ou d'omissions. Je ne peux être tenu responsable de l'utilisation faite de ces informations.</p>
            <h2 className="text-xl font-semibold mt-4">Liens Externes :</h2>
            <p className="mt-2">
            Ce site peut contenir des liens vers d'autres sites. Je ne suis pas responsable du contenu ou des pratiques de confidentialité des sites externes.
            </p>

            <h2 className="text-xl font-semibold mt-4">Droit Applicable et Juridiction :</h2>
            <p className="mt-2">
            Les présentes mentions légales sont régies par le droit belge. Tout litige relatif à l'utilisation de ce site sera soumis à la juridiction des tribunaux de Belgique.
            </p>

            <h2 className="text-xl font-semibold mt-4">Modifications des Mentions Légales :</h2>
            <p className="mt-2">
            Je me réserve le droit de modifier ces mentions légales à tout moment. Les utilisateurs sont invités à les consulter régulièrement pour prendre connaissance des éventuelles modifications.
            </p>


        </div>
    );
}

export default LegalNotice;
