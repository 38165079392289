import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { FaArrowUp } from "react-icons/fa";
import NavBar from './components/partials/NavBar';
import Home from './components/Home';
import Skills from './components/Skills';
import Education from './components/Education';
import Projects from './components/Projects';
import Contact from './components/Contact';
import Footer from './components/partials/Footer';
import Project1 from './components/projects/Project1';
import Project2 from './components/projects/Project2';
import Project3 from './components/projects/Project3';
import Project4 from './components/projects/Project4';
import About from './components/About';
import LegalNotice from './components/partials/LegalNotice';

function App() {
    const [showScrollToTop, setShowScrollToTop] = useState(false);
    const [darkMode, setDarkMode] = useState(false); // État pour le mode sombre

    useEffect(() => {
      const handleScroll = () => {
        setShowScrollToTop(window.scrollY > 200);
      };
      
      window.addEventListener('scroll', handleScroll);

      // Appliquer la couleur de fond en fonction du mode sombre
      document.body.style.backgroundColor = darkMode ? '#384454' : 'white'; // ou toute autre couleur

      // Appliquer la classe dark au body (si nécessaire pour d'autres styles)
      darkMode ? document.body.classList.add('dark') : document.body.classList.remove('dark');

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, [darkMode]);

    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const toggleDarkMode = () => setDarkMode(!darkMode); // Fonction pour basculer le mode sombre

    return (
      <Router>
        <div>
          <NavBar darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
          <Routes>
            <Route path='/contact' element={<Contact/>} />
            <Route path='/project-1' element={<Project1/>} />
            <Route path='/project-2' element={<Project2/>} />
            <Route path='/project-3' element={<Project3/>} />
            <Route path='/project-4' element={<Project4/>} />
            <Route path='/about' element={<About/>} />
            <Route path='/mentions-legales' element={<LegalNotice/>} />
            <Route index element={<DefaultRoutes />} />
            <Route path='/' element={<DefaultRoutes />} />
          </Routes>
          <Footer />
        </div>
        {showScrollToTop && (
          <button
            className="fixed bottom-4 right-4 bg-blu-lo hover:animate-bounce text-white py-2 px-4 rounded-full"
            onClick={scrollToTop}
          >
            <FaArrowUp size={20} />
          </button>
        )}
      </Router>
    );
}

const DefaultRoutes = () => (
  <div>
    <Home />
    <Skills />
    <Education />
    <Projects />
  </div>
);

export default App;
