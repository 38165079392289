import React, { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import emailjs from '@emailjs/browser';
import ReCAPTCHA from 'react-google-recaptcha';

const Contact = () => {
  const schema = yup
    .object({
      email: yup
        .string()
        .email('Merci de rentrer une adresse mail valide')
        .max(255)
        .required('Merci de rentrer une adresse mail'),
      subject: yup.string().required('Le sujet est obligatoire'),
      message: yup.string().required('Merci de rentrer un message'),
    })
    .required();

    const {
      register,
      formState: { errors },
      handleSubmit,
      reset,
    } = useForm({
      resolver: yupResolver(schema),
    });
  
    // État pour vérifier si le CAPTCHA a été validé
    const [captchaValidated, setCaptchaValidated] = useState(false);
    // État pour déterminer si le mode compact doit être utilisé
    const [isCompact, setIsCompact] = useState(window.innerWidth < 440);
    // Référence au composant reCAPTCHA
    const recaptchaRef = useRef(null);
  
    // Gestion du redimensionnement de la fenêtre
    useEffect(() => {
      const handleResize = () => {
        const currentIsCompact = window.innerWidth < 440;
        if (currentIsCompact !== isCompact) {
          setIsCompact(currentIsCompact);
          if (recaptchaRef.current) {
            recaptchaRef.current.reset();
          }
        }
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, [isCompact]);
  
    // Gestion des changements sur le CAPTCHA
    const onCaptchaChange = (value) => {
      setCaptchaValidated(!!value);
    };
  
    // Gestion de la soumission du formulaire
    const onSubmit = (data) => {
      if (!captchaValidated) {
        alert('Veuillez compléter le CAPTCHA');
        return;
      }
  
      // Paramètres pour emailjs
      const templateId = 'template_g5pu9va';
      const serviceId = 'service_30kbepo';
      sendFeedback(serviceId, templateId, {
        email: data.email,
        subject: data.subject,
        message: data.message,
        reply_to: '', // Ajustez selon vos besoins
      });
  
      reset(); // Réinitialiser le formulaire et le CAPTCHA après l'envoi
      setCaptchaValidated(false);
    };
  
    // Fonction pour envoyer le feedback via emailjs
    const sendFeedback = (serviceId, templateId, variables) => {
      emailjs
        .send(serviceId, templateId, variables, 'RRMVMC3xP7zznp46W')
        .then((res) => {
          console.log('succès');
        })
        .catch((err) => console.error('Il y a une erreur'));
    };
  

  return (
    <div className="sm:w-11/12 lg:w-9/12 mx-auto p-6 ">
      <h1 className="text-3xl font-bold text-center mt-16 mb-6 text-black-lo dark:text-white-lo">Contact</h1>

      <form className="w-full max-w-lg mx-auto bg-white dark:bg-black-lo  shadow-lg rounded-lg px-8 pt-6 pb-8 mb-6" onSubmit={handleSubmit(onSubmit)}>
        
        <div className="mb-6">
          <label htmlFor="email" className="block text-black-lo dark:bg-black-lo dark:text-white-lo text-sm font-bold mb-4">
            Email :
          </label>
          <input
            className=" shadow appearance-none border rounded w-full py-2 px-3 text-black-lo dark:bg-black-lo dark:text-white-lo leading-tight focus:outline-none focus:shadow-outline"
            type="email"
            id="email"
            name="email"
            placeholder="exemple@gmail.com"
            {...register('email')}
          />
          {errors.email && <p className="text-red-500 text-xs italic">{errors.email.message}</p>}
        </div>

        <div className="mb-4">
          <label htmlFor="subject" className="block text-black-lo dark:bg-black-lo dark:text-white-lo text-sm font-bold mb-4">
            Objet:
          </label>
          <select
            className="block appearance-none w-full bg-white dark:bg-black-lo border dark:text-white-lo border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white dark:focus:bg-black-lo focus:border-gray-500"
            id="subject"
            name="subject"
            {...register('subject')}
          >
            <option value="">Sélectionner l'objet de la demande</option>
            <option value="Demande de collaboration">Demande de collaboration</option>
            <option value="Projet Web">Projet Web</option>
            <option value="Problème technique">Problème technique</option>
            <option value="Partenariat">Partenariat</option>
            <option value="Feedback">Feedback</option>
            <option value="Demande d'emploi">Demande d'emploi</option>
            <option value="Autre">Autre</option>
          </select>
        </div>

        <div className="mb-4">
          <label htmlFor="message" className="block text-black-lo dark:bg-black-lo dark:text-white-lo text-sm font-bold mb-4">
            Message :
          </label>
          <textarea
            className="shadow appearance-none border rounded w-full py-2 px-3 dark:bg-black-lo dark:text-white-lo text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="message"
            name="message"
            placeholder="Dites moi en un peu plus..."
            rows="4"
            {...register('message')}
          ></textarea>
          {errors.message && <p className="text-red-500 text-xs italic">{errors.message.message}</p>}
        </div>
        <ReCAPTCHA
          sitekey="6LdpT08pAAAAALIlGg3Qr-8dr-ZQy4s3eIZcsgYG"
          ref={recaptchaRef}
          onChange={onCaptchaChange}
          size={isCompact ? 'compact' : 'normal'}
          className="mb-4 sm:transform sm:ml-2 midsm:ml-4 midsm:scale-100 flex flex-start" 
        />
        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full hover:shadow-lg hover:shadow-blu-lo focus:outline-none focus:shadow-outline" type="Submit">
          Envoyer
        </button>
      </form>
    </div>
  );
};

export default Contact;
