import React, { useState } from 'react';
import { Twirl as Hamburger } from 'hamburger-react'
import { FaGithub, FaLinkedin } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { LuSun } from "react-icons/lu";
import { FaRegMoon } from "react-icons/fa";

const NavBar = ({ darkMode, toggleDarkMode }) => {
  const [isOpen, setOpen] = useState(false)

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className='z-50 flex justify-between items-center w-full h-20 px-4 fixed bg-white text-black-lo dark:bg-black-lo dark:text-white-lo'>
        <div className='flex items-center'>
        <Link to="/" onClick={() => scrollToSection('home')} duration={500} className='font-bold'>
          <span className='text-yellow-lo'>LOICK</span>
          <span className="text-blue-lo">BUCK</span>
        </Link>
        <button onClick={toggleDarkMode} className="ml-5">
                {darkMode ? <FaRegMoon size={25} /> : <LuSun size={25} />}
            </button>
        </div>

      <ul className='hidden lg:flex items-center font-medium dark:text-white-lo'>
        <Link to='about' className='px-4 cursor-pointer capitalize underline'>
          À propos
        </Link>
        <Link to='/' onClick={() => scrollToSection('skills')} className='px-4 cursor-pointer capitalize underline'>
          Compétences
        </Link>
        <Link to='/' onClick={() => scrollToSection('projects')} className='px-4 cursor-pointer capitalize underline'>
          Projets
        </Link>
      </ul>
      <ul className='hidden lg:flex items-center '>
        <li className='px-5 cursor-pointer capitalize font-bold'>
          <Link to='https://www.linkedin.com/in/loick-buck-9a7b74273/?originalSubdomain=be' className='flex flex-row items-center text-black-lo hover:text-indigo-400 hover:ease-in-out duration-500 dark:text-white-lo' >
            <FaLinkedin size={30} />
            LinkedIn
          </Link>
        </li>
        <li className='px-5 cursor-pointer capitalize font-bold'>
          <Link to='https://github.com/LoickBck' className='flex flex-row items-center text-black-lo hover:text-black hover:ease-in-out duration-500 dark:text-white-lo'>
            <FaGithub size={30} />
            Github
          </Link>
        </li>
        <div className="px-5 flex items-center justify-center">
          <Link className="bouton-contact w-full rounded-sm bg-gradient-to-t from-yellow-lo to-blu-lo p-[3px] hover:from-blu-lo hover:to-yellow-lo"  to='/contact'>
            <div className='bg-white dark:bg-black-lo rounded-sm'>
            <div className="flex h-full w-full items-center justify-center dark:text-white-lo">
              <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                <path d="M33 8.25H13.5C11.85 8.25 10.5 9.6 10.5 11.25V24.75C10.5 26.415 11.85 27.75 13.5 27.75H33C34.665 27.75 36 26.415 36 24.75V11.25C36 9.6 34.665 8.25 33 8.25ZM33 24.75H13.5V13.755L23.25 18.75L33 13.755V24.75ZM23.25 16.215L13.5 11.25H33L23.25 16.215ZM7.5 24.75C7.5 25.005 7.545 25.245 7.575 25.5H1.5C0.672 25.5 0 24.825 0 24C0 23.175 0.672 22.5 1.5 22.5H7.5V24.75ZM4.5 10.5H7.575C7.545 10.755 7.5 10.995 7.5 11.25V13.5H4.5C3.675 13.5 3 12.825 3 12C3 11.175 3.675 10.5 4.5 10.5ZM1.5 18C1.5 17.175 2.175 16.5 3 16.5H7.5V19.5H3C2.175 19.5 1.5 18.825 1.5 18Z" className='fill-black-lo dark:fill-white-lo'/>
              </svg>
              <Link className="font-bold mr-2" to='/contact'>
                Contactez-Moi
              </Link>
            </div>
            </div>
          </Link>
        </div>
      </ul>

      <div
        className="hamburger lg:hidden h-12 z-10 text-white-lo dark:text-black-lo cursor-pointer rounded-3xl dark:bg-white-lo1 bg-black-lo flex items-center"
        onClick={() => setOpen(!isOpen)}
      >
        <Hamburger rounded toggled={isOpen} size={22} toggle={setOpen} />
      </div>

      {isOpen && (
        <ul className='flex flex-col justify-center items-center absolute top-0 left-0 w-full h-screen bg-white dark:bg-black-lo transition-opacity transform duration-500'>
          <li className='px-4 cursor-pointer capitalize py-6 text-2xl'>
            <Link onClick={() => setOpen(!isOpen)} to='/about'>À propos</Link>
          </li>
          <li onClick={() => setOpen(!isOpen)} className='px-4 cursor-pointer capitalize py-6 text-2xl'>
            <Link to='/' onClick={() => scrollToSection('skills')}>Compétences</Link>
          </li>
          <li onClick={() => setOpen(!isOpen)} className='px-4 cursor-pointer capitalize py-6 text-2xl'>
            <Link to='/' onClick={() => scrollToSection('projects')}>Projets</Link>
          </li>
          <li className='px-5 cursor-pointer capitalize py-6 text-2xl font-bold items-center'>
            <Link to='https://www.linkedin.com/in/loick-buck-9a7b74273/?originalSubdomain=be' className='flex flex-row items-center dark:text-white-lo text-black-lo hover:text-indigo-400 hover:ease-in-out duration-500'>
              <FaLinkedin size={30} />
              LinkedIn
            </Link>
          </li>
          <li className='px-5 cursor-pointer capitalize font-bold flex items-center py-6 text-2xl'>
            <Link to='https://github.com/LoickBck' className='flex flex-row items-center text-black-lo dark:text-white-lo hover:text-black hover:ease-in-out duration-500'>
              <FaGithub size={30} />
              Github
            </Link>
          </li>
          <div className="px-5 flex items-center justify-center py-6 text-1xl">
            <Link onClick={() => setOpen(!isOpen)} className="w-full rounded-md bg-gradient-to-t from-yellow-lo to-blu-lo p-[3px] hover:from-blu-lo hover:to-yellow-lo"  to='/contact'>
              <div className="flex h-full w-full items-center justify-center bg-white dark:bg-black-lo">
                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                  <path d="M33 8.25H13.5C11.85 8.25 10.5 9.6 10.5 11.25V24.75C10.5 26.415 11.85 27.75 13.5 27.75H33C34.665 27.75 36 26.415 36 24.75V11.25C36 9.6 34.665 8.25 33 8.25ZM33 24.75H13.5V13.755L23.25 18.75L33 13.755V24.75ZM23.25 16.215L13.5 11.25H33L23.25 16.215ZM7.5 24.75C7.5 25.005 7.545 25.245 7.575 25.5H1.5C0.672 25.5 0 24.825 0 24C0 23.175 0.672 22.5 1.5 22.5H7.5V24.75ZM4.5 10.5H7.575C7.545 10.755 7.5 10.995 7.5 11.25V13.5H4.5C3.675 13.5 3 12.825 3 12C3 11.175 3.675 10.5 4.5 10.5ZM1.5 18C1.5 17.175 2.175 16.5 3 16.5H7.5V19.5H3C2.175 19.5 1.5 18.825 1.5 18Z" className='fill-black-lo dark:fill-white-lo'/>
                </svg>
                <div className="font-bold mr-2">
                  Contactez-Moi
                </div>
              </div>
            </Link>
          </div>
        </ul>
      )}
    </div>
  );
};

export default NavBar;
