import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { FaHtml5, FaPhp, FaReact, FaBootstrap, FaFigma, FaSymfony, FaGithub, FaCss3 } from "react-icons/fa";
import { SiMysql, SiJavascript, SiTailwindcss, SiNextdotjs , SiPrestashop } from "react-icons/si";
import { DiWordpress, DiPhotoshop, DiSass } from "react-icons/di";

const Education = () => {
    const [ref, inView] = useInView();

    const itemVariants = {
        hidden: { opacity: 0, y: -20 },
        visible: i => ({
            opacity: 1,
            y: 0,
            transition: { delay: i * 0.1 }
        }),
    };

    const icons = [
        { component: FaHtml5, hoverClass: 'hover:text-orange-500' },
        { component: FaReact, hoverClass: 'hover:text-blue-300' },
        { component: FaFigma, hoverClass: 'hover:text-green-500' },
        { component: FaBootstrap, hoverClass: 'hover:text-pink-500' },
        { component: FaSymfony, hoverClass: 'hover:text-black' },
        { component: FaGithub, hoverClass: 'hover:text-black' },
        { component: FaCss3, hoverClass: 'hover:text-blue-500' },
        { component: SiMysql, hoverClass: 'hover:text-yellow-300' },
        { component: FaPhp, hoverClass: 'hover:text-indigo-500' },
        { component: SiJavascript, hoverClass: 'hover:text-yellow-500' },
        { component: SiTailwindcss, hoverClass: 'hover:text-blue-200' },
        { component: SiNextdotjs, hoverClass: 'hover:text-black' },
        { component: DiWordpress, hoverClass: 'hover:text-black' },
        { component: DiPhotoshop, hoverClass: 'hover:text-purple-500' },
        { component: SiPrestashop, hoverClass: 'hover:text-gray-600' },
        { component: DiSass, hoverClass: 'hover:text-pink-200' }
        // ... Ajoutez le reste de vos icônes avec les classes de survol correspondantes
    ];

    const IconComponents = icons.map((icon, index) => {
        const IconComponent = icon.component;
        return (
            <motion.div
                key={index}
                custom={index}
                initial="hidden"
                animate={inView ? "visible" : "hidden"}
                variants={itemVariants}
                className="icon-wrapper m-5"
            >
                <IconComponent className={`icon text-blue-lo ${icon.hoverClass} hover:ease-in-out duration-500`} size={70} />
            </motion.div>
        );
    });

    return (
        <div ref={ref} className="education h-mid-screen w-full lg:mb-44 md:mb-32 sm:mb-[30rem] dark:bg-black-lo">
            <p className="mt-8 text-black-lo text-3xl ml-4 dark:text-white-lo">Ma Formation</p>
            <div className="lg:mt-20 sm:mt-8 grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 place-items-center">
                {IconComponents}
            </div>
        </div>
    );
};

export default Education;
