import React from 'react';
import { Link } from 'react-router-dom';
import { TERipple } from 'tw-elements-react';

const Projects = () => {
    return (
        <div id="projects" className='flex flex-col md:mt-[20rem] dark:bg-black-lo'>
            <p className='text-black-lo text-3xl ml-4 my-10 dark:text-white-lo'>Mes projets</p>
        <div className='grid grid-cols-1 md:grid-cols-2
         lg:grid-cols-2 md:gap-y-[20rem] sm:gap-y-[20rem] xs:gap-y-[24rem] lg:gap-y-[22rem] xlg:gap-y-[32rem] place-items-center mt-10 md:mx-16 xlg:mx-32 xlg:mb-[30rem] lg:mb-[28rem] sm:mb-[24rem]'>
            
            <div
        className="block rounded-lg w-10/12 h-72 stroke-2 stroke-blu-lo">
        <div>
          <img
            className="rounded-t-lg p-2 py-10 bg-black-lo dark:bg-white-lo"
            src="https://i.postimg.cc/1z7jz635/tropical-heatwave-high-resolution-logo-transparent.png"
            alt="Tropical Heatwave" />
        </div>
        <div className="p-6 rounded-b-lg bg-black-lo dark:bg-white-lo">
          <h5
            className="mb-2 text-xl font-semibold text-white dark:text-black-lo text-center">
            Projet 1 | Tropical Heatwave
          </h5>
          <div className="h-[2px] w-1/2 mx-auto my-4 place-self-center bg-blu-lo"></div>
          <p className="mb-4 text-center text-white dark:text-black-lo">
          Tropical Heatwave est une célébration vibrante de la musique, rassemblant des artistes du monde entier dans un festival électrisant.
          </p>
          <TERipple>
            <Link
              to='/project-1'
              type="button"
              className="inline-block bg-blu-lo px-6 pb-2 pt-2.5 text-md rounded-lg bg-gradient-to-br from-blu-lo to-blue-lo font-medium text-white transition duration-200 hover:shadow-lg hover:shadow-blu-lo">
              Voir le projet
            </Link>
          </TERipple>
        </div>
            </div>
            <div
        className="block rounded-lg w-10/12 h-72 stroke-2 stroke-blu-lo xs:mb-32 md:mb-16">
        <div>
          <img
            className="rounded-t-lg p-2 bg-black-lo dark:bg-white-lo"
            src="https://i.postimg.cc/Y9nRRwWr/logo-Silver-Ride.png"
            alt="Silver Ride" />
        </div>
        <div className="p-6 rounded-b-lg bg-black-lo dark:bg-white-lo">
          <h5
            className="mb-2 text-xl font-semibold text-white dark:text-black-lo text-center">
            Projet 2 | Silver Ride
          </h5>
          <div className="h-[2px] w-1/2 mx-auto my-4 place-self-center bg-blu-lo"></div>
          <p className="mb-4 text-center text-white-lo dark:text-black-lo">
          Silver Ride est votre destination ultime pour l'achat et la découverte de voitures.
          </p>
          <TERipple>
            <Link
              to='/project-2'
              type="button"
              className="inline-block bg-blu-lo px-6 pb-2 pt-2.5 text-md rounded-lg bg-gradient-to-br from-blu-lo to-blue-lo font-medium text-white transition duration-200 hover:shadow-lg hover:shadow-blu-lo">
              Voir le projet
            </Link>
          </TERipple>
        </div>
            </div>
            <div
        className="block rounded-lg w-10/12 h-72 stroke-2 stroke-blu-lo">
        <div>
          <img
            className="rounded-t-lg p-2 bg-black-lo dark:bg-white-lo"
            src="https://i.postimg.cc/8PcxjRZg/Capture-d-cran-2024-02-20-094858.png"
            alt="Astro Nova" />
        </div>
        <div className="p-6 rounded-b-lg bg-black-lo dark:bg-white-lo">
          <h5
            className="mb-2 text-xl font-semibold text-white dark:text-black-lo text-center">
            Projet 3 | Astro Nova
          </h5>
          <div className="h-[2px] w-1/2 mx-auto my-4 place-self-center bg-blu-lo"></div>
          <p className="mb-4 text-center text-white-lo dark:text-black-lo">
          Astro Nova vous emmène dans un voyage à travers le système solaire. Découvrez les merveilles de l'espace, des planètes aux étoiles lointaines.
          </p>
          <TERipple>
            <Link
              to='/project-3'
              type="button"
              className="inline-block bg-blu-lo px-6 pb-2 pt-2.5 text-md rounded-lg bg-gradient-to-br from-blu-lo to-blue-lo font-medium text-white transition duration-200 hover:shadow-lg hover:shadow-blu-lo">
              Voir le projet
            </Link>
          </TERipple>
        </div>
            </div>
            <div
        className="lg:mt-32 sm:mt-0 block rounded-lg w-10/12 h-72 stroke-2 stroke-blu-lo">
        <div>
          <img
            className="rounded-t-lg p-2 bg-black-lo dark:bg-white-lo"
            src="https://i.postimg.cc/xCHdmkn9/Sans-titre-2.png"
            alt="VMultiMediaStore" />
        </div>
        <div className="p-6 rounded-b-lg bg-black-lo dark:bg-white-lo">
          <h5
            className="mb-2 text-xl font-semibold text-white dark:text-black-lo text-center">
            Projet 4 | V MultiMedia Store
          </h5>
          <div className="h-[2px] w-1/2 mx-auto my-4 place-self-center bg-blu-lo"></div>
          <p className="mb-4 text-center text-white-lo dark:text-black-lo">
            V MultiMedia Store est votre guichet unique pour tous vos besoins en multimédia.
          </p>
          <TERipple>
            <Link
              to='/project-4'
              type="button"
              className="inline-block bg-blu-lo px-6 pb-2 pt-2.5 text-md rounded-lg bg-gradient-to-br from-blu-lo to-blue-lo font-medium text-white transition duration-200 hover:shadow-lg hover:shadow-blu-lo">
              Voir le projet
            </Link>
          </TERipple>
        </div>
            </div>
            
        </div>
        </div>
    )
}

export default Projects