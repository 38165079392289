import React from "react";
import { Carousel } from "@material-tailwind/react";
import { Link } from 'react-router-dom';
import { TbWorldWww } from "react-icons/tb";
import { SiPrestashop} from "react-icons/si";

const Project3 = () => {
    return (
        <div className='project flex flex-col '>
            <div className='my-16'></div>
        <div className='grid grid-cols-1 place-items-center mt-10 xs:mb-[28rem] sm:mb-[44rem] lg:mx-24'>
            
            <div
        className="block rounded-lg w-10/12 h-72 stroke-2 stroke-blu-lo xs:mb-[22rem] sm:mb-[44rem] md:mb-[20rem] lg:mb-[20rem] xlg:mb-[20rem]">
          <Carousel
          className="rounded-t-lg h-[28rem]"
          navigation={({ setActiveIndex, activeIndex, length }) => (
          <div className="absolute bottom-4 left-2/4 z-50 flex -translate-x-2/4 gap-2">
            {new Array(length).fill("").map((_, i) => (
              <span
                key={i}
                className={`block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${
                  activeIndex === i ? "w-8 bg-white" : "w-4 bg-white/50"
                }`}
                onClick={() => setActiveIndex(i)}
              />
            ))}
          </div>
        )}
        >
        <img
          src="https://i.postimg.cc/xCHdmkn9/Sans-titre-2.png"
          alt="Logo du site VMultiMedia"
          className="h-full w-full object-scale-down bg-black-lo"
        />
        <img
          src="https://i.postimg.cc/4xvjZRk3/Capture-d-cran-2024-02-20-092208.png"
          alt="Screenshot du site VMultiMedia 1"
          className="h-full w-full object-cover"
        />
        <img
          src="https://i.postimg.cc/J0mPpG0c/Capture-d-cran-2024-02-20-092423.png"
          alt="Screenshot du site VMultiMedia 2"
          className="h-full w-full object-cover"
        />
        <img
          src="https://i.postimg.cc/mrpPdqrd/Capture-d-cran-2024-02-20-092719.png"
          alt="Screenshot du site VMultiMedia 3"
          className="h-full w-full object-cover"
        />
        </Carousel>
        <div className="p-6 rounded-b-lg bg-black-lo lg:mb-64">
          <h5
            className="mb-2 text-2xl font-semibold text-white text-center">
            V MultiMedia
            <div className="h-[2px] xlg:w-1/4 lg:w-1/3 sm:w-1/2 mx-auto mt-4 place-self-center bg-blu-lo"></div>
          </h5>
          
          <div className="xs:flex grid grid-cols-2 xs:flex-row items-center justify-center content-center">
          <SiPrestashop className='my-5 ml-6 xs:mx-0 text-gray-400' size={70} />
          </div>
          <p className="mb-8 text-center text-white">
          V MultiMedia Store est la concrétisation de ma vision pour un shopping multimédia sans limites. Ce site propose une sélection premium de produits multimédias, de jeux vidéo à l'électronique de pointe, visant à simplifier l'achat en ligne pour les amateurs de technologie. <br /> <br />

          En vous inscrivant sur V MultiMedia Store, vous entrez dans un monde où la qualité et la performance des produits vont de pair avec une expérience utilisateur exceptionnelle. L'utilisation de technologies web avancées assure une navigation aisée et une transaction sécurisée, vous permettant de trouver et d'acheter vos produits préférés avec une facilité déconcertante. <br /> <br />

          Je ne peux évidemment pas le mettre en ligne avec un accès libre car s'il y a une commande je devrais l'envoyer .Pour voir la boutique utiliser l'adresse : client@vmultimedia.be et le mot de passe : motdepassenul2024.Puis clicker sur le bouton en haut à droite "Voir ma boutique" avec un oeil à côté. Ou alors enlever admin123 de la barre de recherche 

          </p>
          <p className="mb-8 text-end text-white">Les liens vers le site et le git du projet se trouvent juste en dessous</p>
          <div className="flex flex-col xs:flex-row items-center mx-4">
            <Link
            to='http://www.vmultimediastore.buckloick.com/admin123'
            className="mr-4 linear flex flex-row items-center rounded-full bg-gradient-to-br from-blu-lo to-blue-lo px-3 py-3 text-base font-medium text-white transition duration-200 hover:shadow-lg hover:shadow-blu-lo"
            data-ripple-light
            >
            <TbWorldWww />
            Voir le projet
            </Link>
            </div>
        </div>
        </div>
        </div>
    </div>
    )
}

export default Project3;