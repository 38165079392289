import React, { useEffect, useState } from 'react';
import HeroImage from '../assets/heroImage.png';
import Typewriter from "typewriter-effect";
import { motion } from 'framer-motion';

const Home = () => {
  const [isVisible, setIsVisible] = useState(false);

  const checkVisibility = () => {
    const element = document.getElementById('home');
    if (element) {
      const rect = element.getBoundingClientRect();
      setIsVisible(rect.top <= window.innerHeight && rect.bottom >= 0);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', checkVisibility);
    checkVisibility();
    return () => window.removeEventListener('scroll', checkVisibility);
  }, []);

  const variants = {
    hidden: { opacity: 0, x: '-100%' },
    visible: { opacity: 1, x: 0 },
  };

  return (
    <div>
      <motion.div 
        id='home'
        className='h-screen w-full midsm:mb-[6rem] sm:mb-[14rem] md:mb-0 dark:bg-black-lo dark:text-white-lo'
      >
          <motion.div className='max-w flex flex-col items-center h-full px-4 lg:flex-row '>
              <motion.div 
              className='text-center w-1/2 mx-auto lg:mt-0 sm:mt-[8rem]'
              animate={isVisible ? "visible" : "hidden"}
              variants={variants}
              transition={{ type: 'spring', damping: 15 }}
              >
                <h2 className='text-4xl sm:text-4xl xs:text-6xl font-bold text-blu-lo'>
                  <Typewriter
                    options={{
                      autoStart: true,
                      loop: true,
                      delay: 100,
                      strings: [
                        "< Loick<br>Buck />",
                        "< Je suis<br>web developer. />"
                      ]
                    }}
                  />
                </h2>
                <p className='py-10 text-2xl text-black-lo dark:text-white-lo'>Web Developer Junior</p>
              </motion.div>
              <motion.div 
              className='lg:w-1/2 md:w-3/4 sm:w-10/12'
              animate={isVisible ? "visible" : "hidden"}
              variants={variants}
              transition={{ type: 'spring', damping: 15 }}
              >
                <img src={HeroImage} alt="my profile" className='rounded-[50%] mx-auto xlg:w-8/12 lg:w-9/12 bg-black-lo dark:bg-white-lo' />
              </motion.div>
          </motion.div>
      </motion.div>
    </div>
  )
}

export default Home;