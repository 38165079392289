import React from "react";
import { Link } from "react-router-dom";
import { Typography } from "@material-tailwind/react";

const Footer = () => {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };
    return(
    <footer className=" h-full w-full bg-white dark:bg-black-lo mt-[10rem]">
      <div className=" bg-white dark:bg-black-lo text-center md:justify-between">
        <ul className="flex md:flex-row md:justify-end sm:flex-col sm:justify-center">
          <li>
          <Link to="/mentions-legales" >
            <Typography
              as="a"
              className="font-normal transition-colors text-black-lo dark:text-white-lo hover:text-blu-lo mx-4 sm:my-2"
            >
              Mentions Légales
            </Typography>
            </Link>
          </li>
          <li>
          <Link to="https://github.com/LoickBck">
            <Typography
              as="a"
              className="font-normal transition-colors text-black-lo dark:text-white-lo hover:text-blu-lo mx-4 sm:my-2"
            >
              Github
            </Typography>
            </Link>
          </li>
          <li>
          <Link to='/' onClick={() => scrollToSection('projects')}>
            <Typography
              as="a"
              className="font-normal transition-colors text-black-lo dark:text-white-lo hover:text-blu-lo mx-4 sm:my-2"
            >
              Projets
            </Typography>
            </Link>
          </li>
          <li>
          <Link to="/contact">
            <Typography
              as="a"
              className="font-normal transition-colors text-black-lo dark:text-white-lo hover:text-blu-lo mx-4 sm:my-2"
            >
             
              Contact
            </Typography>
            </Link>
          </li>
        </ul>
      </div>
      <hr className="my-6 mx-10 border-white-lo" />
        <Typography className="text-center font-normal text-black-lo dark:text-white-lo">
          &copy; 2023 Buck Loick
        </Typography>
        <div>
          <div className="w-full">
            <svg className="waves -mx-4"xmlns="http://www.w3.org/2000/svg"
            viewBox="0 24 170 28" preserveAspectRatio="none" shapeRendering="auto">
            <defs>
                <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
            </defs>
              <g className="parallax w-full">
                <use href="#gentle-wave" x="48" y="0" fill="#3F83C1" />
                <use href="#gentle-wave" x="48" y="3" fill="#317AC1" />
                <use href="#gentle-wave" x="48" y="5" fill="#0a5ead" />
                <use href="#gentle-wave" x="48" y="7" fill="#2f6599" />
              </g>
            </svg>
        </div>
      </div>
    </footer>
  );
};

export default Footer