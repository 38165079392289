import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { FaCode, FaTags } from "react-icons/fa";
import { PiStudentFill } from "react-icons/pi";
import { FaFeatherPointed } from "react-icons/fa6";
import {
  Card,
  CardBody,
  Typography,
} from "@material-tailwind/react";

const Skills = () => {
  const [isVisible, setIsVisible] = useState(false);

  const checkVisibility = () => {
    const element = document.getElementById('skills');
    if (element) {
      const rect = element.getBoundingClientRect();
      setIsVisible(rect.bottom > 0 && rect.top < window.innerHeight);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      checkVisibility();
    };

    window.addEventListener('scroll', handleScroll);
    checkVisibility();

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const cardVariants = {
    hidden: { x: '-100vw', opacity: 0 },
    visible: { x: 0, opacity: 1 }
  };

  const cards = [
    { id: 1, icon: <PiStudentFill />, title: "Apprentissage continu", content: "Développeur web avide de découvertes, mon portfolio raconte l'histoire de mon voyage d'apprentissage. Chaque ligne de code représente un pas vers la maîtrise." },
    { id: 2, icon: <FaTags />, title: "Gestion de BDD", content: "Chaque table, chaque requête représente une pièce du puzzle de mon parcours. Où la passion pour les données rencontre l'art de la création." },
    { id: 3, icon: <FaFeatherPointed />, title: "UI & UX Design", content: "Où la créativité rencontre la fonctionnalité. Chaque élément visuel est une pièce soigneusement placée pour stimuler une expérience utilisateur immersive et intuitive." },
    { id: 4, icon: <FaCode />, title: "Development", content: "Mon parcours se compose de projets où la passion pour la programmation rencontre la quête incessante de solutions ingénieuses. Chaque projet est une exploration passionnante où la logique rencontre la créativité." },
  ];

  return (
    <div id="skills" className='h-full w-full flex flex-col mb-32 xlg:mt-0 md:mt-[20rem] sm:mt-[20rem] dark:bg-black-lo'>
      <p className='text-black-lo ml-3 text-3xl mb-4 lg:mb-5 lg:mt-2 lg:mr-4 dark:text-white-lo'>Mes Compétences</p>
      <div className='h-full w-full lg:flex lg:justify-center lg:space-x-4 md:grid grid-cols-2'>
        {cards.map((card) => (
          <motion.div
            key={card.id}
            variants={cardVariants}
            initial='hidden'
            animate={isVisible ? 'visible' : 'hidden'}
            transition={{ type: 'spring', stiffness: 120, delay: card.id * 0.1 }}
            className="mt-6 lg:w-1/3 md:w-9/12 sm:w-4/5 mx-auto place-self-center items-center justify-self-center"
          >
            <Card className="h-full">
              <CardBody className='bg-black-lo rounded-md p-2 dark:bg-white-lo'>
                <div className='h-14 w-16 text-4xl m-4 rounded-md text-yellow-lo bg-blu-lo p-2 dark:bg-blue-lo'>
                  {card.icon}
                </div>
                <Typography variant="h5" className="mb-4 text-white-lo dark:text-black-lo">
                  {card.title}
                </Typography>
                <Typography className='text-white-lo mb-4 text-start dark:text-black-lo'>
                  {card.content}
                </Typography>
              </CardBody>
            </Card>
          </motion.div>
        ))}
      </div>
    </div>
  );
}

export default Skills;
