import React from "react";
import { Carousel } from "@material-tailwind/react";
import { Link } from 'react-router-dom';
import { FaGithub, FaReact } from "react-icons/fa";
import { TbWorldWww } from "react-icons/tb";
import { SiTailwindcss} from "react-icons/si";

const Project3 = () => {
    return (
        <div className='project flex flex-col '>
            <div className='my-16'></div>
        <div className='grid grid-cols-1 place-items-center mt-10 xs:mb-[28rem] sm:mb-[44rem] lg:mx-24'>
            
            <div
        className="block rounded-lg w-10/12 h-72 stroke-2 stroke-blu-lo xs:mb-[22rem] sm:mb-[44rem] md:mb-[20rem] lg:mb-[20rem] xlg:mb-[20rem]">
          <Carousel
          className="rounded-t-lg h-[28rem]"
          navigation={({ setActiveIndex, activeIndex, length }) => (
          <div className="absolute bottom-4 left-2/4 z-50 flex -translate-x-2/4 gap-2">
            {new Array(length).fill("").map((_, i) => (
              <span
                key={i}
                className={`block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${
                  activeIndex === i ? "w-8 bg-white" : "w-4 bg-white/50"
                }`}
                onClick={() => setActiveIndex(i)}
              />
            ))}
          </div>
        )}
        >
        <img
          src="https://i.postimg.cc/8PcxjRZg/Capture-d-cran-2024-02-20-094858.png"
          alt="Logo du site Astro Nova"
          className="h-full w-full object-scale-down bg-black-lo"
        />
        <img
          src="https://i.postimg.cc/q721d8PH/Capture-d-cran-2024-02-20-091727.png"
          alt="Screenshot du site Astro Nova 1"
          className="h-full w-full object-cover"
        />
        <img
          src="https://i.postimg.cc/zGsxMdPk/Capture-d-cran-2024-02-20-091835.png"
          alt="Screenshot du site Astro Nova 2"
          className="h-full w-full object-cover"
        />
        <img
          src="https://i.postimg.cc/T20HwJpR/Capture-d-cran-2024-02-20-091959.png"
          alt="Screenshot du site Astro Nova 3"
          className="h-full w-full object-cover"
        />
        </Carousel>
        <div className="p-6 rounded-b-lg bg-black-lo lg:mb-64">
          <h5
            className="mb-2 text-2xl font-semibold text-white text-center">
            Nova Astro
            <div className="h-[2px] xlg:w-1/4 lg:w-1/3 sm:w-1/2 mx-auto mt-4 place-self-center bg-blu-lo"></div>
          </h5>
          
          <div className="xs:flex grid grid-cols-2 xs:flex-row items-center justify-center content-center">
          <FaReact style={{ marginRight: '20px' }}  className='my-5 ml-6 xs:mx-0 text-blue-lo' size={70} />
          <FaGithub style={{ marginRight: '20px' }}  className='my-5 ml-6 xs:mx-0 text-black' size={70} />
          <SiTailwindcss className='my-5 ml-6 xs:mx-0 text-blue-200' size={60} />
          </div>
          <p className="mb-8 text-center text-white">
          Astro Nova, le reflet de mon enthousiasme pour l'astronomie et la découverte spatiale. Ce site est une passerelle vers l'univers, conçu pour éduquer et inspirer par la beauté et le mystère de l'espace. Il offre un voyage visuel et informatif à travers notre système solaire et au-delà.<br /> <br />

          En explorant Astro Nova, vous accédez à un univers de connaissances, où chaque planète, étoile et galaxie est présentée avec des détails fascinants. La technologie moderne et une architecture de site intuitive permettent une exploration fluide de l'espace, rendant l'apprentissage à la fois engageant et accessible.

          </p>
          <p className="mb-8 text-end text-white">Les liens vers le site et le git du projet se trouvent juste en dessous</p>
          <div className="flex flex-col xs:flex-row items-center mx-4">
            <Link
            to='http://novastro.buckloick.com/'
            className="mr-4 linear flex flex-row items-center rounded-full bg-gradient-to-br from-blu-lo to-blue-lo px-3 py-3 text-base font-medium text-white transition duration-200 hover:shadow-lg hover:shadow-blu-lo"
            data-ripple-light
            >
            <TbWorldWww />
            Voir le projet
            </Link>
            <Link
            to='https://github.com/LoickBck/NovaAstro'
            className="linear flex flex-row items-center rounded-full bg-gradient-to-br from-blu-lo to-blue-lo pr-3 py-3 text-base font-medium text-white transition duration-200 hover:shadow-lg hover:shadow-blu-lo sm:mt-4 xs:mt-0"
            data-ripple-light
            >
            <FaGithub />
            GitHub
            </Link>
            </div>
        </div>
        </div>
        </div>
    </div>
    )
}

export default Project3;