import React from "react";
import { Carousel } from "@material-tailwind/react";
import { Link } from 'react-router-dom';
import { FaPhp, FaBootstrap, FaSymfony, FaGithub } from "react-icons/fa";
import { TbWorldWww } from "react-icons/tb";
import { SiMysql, SiJavascript } from "react-icons/si";

const Project2 = () => {
    return (
        <div className='project flex flex-col '>
            <div className='my-16'></div>
        <div className='grid grid-cols-1 place-items-center mt-10 xs:mb-[28rem] sm:mb-[44rem] lg:mx-24'>
            
            <div
        className="block rounded-lg w-10/12 h-72 stroke-2 stroke-blu-lo xs:mb-[22rem] sm:mb-[44rem] md:mb-[20rem] lg:mb-[20rem] xlg:mb-[20rem]">
          <Carousel
          className="rounded-t-lg h-[28rem]"
          navigation={({ setActiveIndex, activeIndex, length }) => (
          <div className="absolute bottom-4 left-2/4 z-50 flex -translate-x-2/4 gap-2">
            {new Array(length).fill("").map((_, i) => (
              <span
                key={i}
                className={`block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${
                  activeIndex === i ? "w-8 bg-white" : "w-4 bg-white/50"
                }`}
                onClick={() => setActiveIndex(i)}
              />
            ))}
          </div>
        )}
        >
        <img
          src="https://i.postimg.cc/Y9nRRwWr/logo-Silver-Ride.png"
          alt="Logo du site Silver Ride"
          className="h-full w-full object-scale-down bg-black-lo"
        />
        <img
          src="https://i.postimg.cc/3x1qJQP3/Capture-d-cran-2023-12-29-000048.png"
          alt="Screenshot du site SilverRide 1"
          className="h-full w-full object-cover"
        />
        <img
          src="https://i.postimg.cc/tR6mnxLS/Capture-d-cran-2023-12-29-000144.png"
          alt="Screenshot du site SilverRide 2"
          className="h-full w-full object-cover"
        />
        <img
          src="https://i.postimg.cc/KzMQT6sk/Capture-d-cran-2023-12-29-000258.png"
          alt="Screenshot du site SilverRide 3"
          className="h-full w-full object-cover"
        />
        </Carousel>
        <div className="p-6 rounded-b-lg bg-black-lo lg:mb-64">
          <h5
            className="mb-2 text-2xl font-semibold text-white text-center">
            Silver Ride
            <div className="h-[2px] xlg:w-1/4 lg:w-1/3 sm:w-1/2 mx-auto mt-4 place-self-center bg-blu-lo"></div>
          </h5>
          
          <div className="xs:flex grid grid-cols-2 xs:flex-row items-center justify-center content-center">
          <SiMysql className='my-5 ml-6 xs:mx-0 text-orange-100' size={70} />
          <FaSymfony className='my-5 ml-6 text-black' size={65} />
          <FaPhp className='my-5 ml-6 text-indigo-800' size={70} />
          <SiJavascript className='my-5 ml-6 text-yellow-500' size={60} />
          <FaBootstrap className='my-5 text-pink-300' size={70} />
          </div>
          <p className="mb-8 text-center text-white">
          Silver Ride, le fruit de mon travail acharné dans le cadre de mon examen. Sur ce site, je propose une expérience en ligne facile pour rendre la vente et l'achat de voitures entre particuliers plus simples. <br /> <br />

          En vous inscrivant sur Silver Ride, vous avez la possibilité de créer un compte personnalisé, où vous pourrez facilement publier des annonces détaillées pour vos voitures. La structure MVC de Symfony et Twig pour les vues garantissent une navigation intuitive.

          </p>
          <p className="mb-8 text-end text-white">Les liens vers le site et le git du projet se trouvent juste en dessous</p>
          <div className="flex flex-col xs:flex-row items-center mx-4">
            <Link
            to='http://silverride.buckloick.com/'
            className="mr-4 linear flex flex-row items-center rounded-full bg-gradient-to-br from-blu-lo to-blue-lo px-3 py-3 text-base font-medium text-white transition duration-200 hover:shadow-lg hover:shadow-blu-lo"
            data-ripple-light
            >
            <TbWorldWww />
            Voir le projet
            </Link>
            <Link
            to='https://github.com/LoickBck/SilverRide'
            className="linear flex flex-row items-center rounded-full bg-gradient-to-br from-blu-lo to-blue-lo pr-3 py-3 text-base font-medium text-white transition duration-200 hover:shadow-lg hover:shadow-blu-lo sm:mt-4 xs:mt-0"
            data-ripple-light
            >
            <FaGithub />
            GitHub
            </Link>
            </div>
        </div>
        </div>
        </div>
    </div>
    )
}

export default Project2;