import React from 'react';
import { FaGithub, FaLinkedin, FaDownload } from 'react-icons/fa';
import { IoMailOpenOutline } from "react-icons/io5";
import HeroImage2 from '../assets/heroImage2.jpg';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const About = () => {
    const containerVariants = {
        hidden: { opacity: 0, y: '-100vh' },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                type: 'spring', // Utilisation d'une animation de type "spring" pour un effet plus dynamique
                stiffness: 80, // Contrôle la raideur du ressort (plus c'est élevé, plus c'est rapide)
                damping: 10, // Contrôle l'amortissement du ressort (plus c'est élevé, plus c'est lent)
            },
        },
    };

    const imageVariants = {
        hidden: { opacity: 0, scale: 0.8 },
        visible: {
            opacity: 1,
            scale: 1,
            transition: {
                type: 'spring',
                stiffness: 80,
                damping: 10,
                delay: 0.2, // Délai pour que l'image commence son animation après le texte
            },
        },
    };

    return (
        <motion.div 
            className='pt-24 h-full'
            initial='hidden'
            animate='visible'
            variants={containerVariants}
        >
            <motion.div className='lg:mx-28 mt-6 sm:mx-8 text-start text-lg dark:text-white-lo text-black-lo'>
            
            <h2 className='text-center'>Si tu te retrouves ici c'est que tu veux en savoir plus sur moi ! Moi, c'est Loick Buck. Je te laisse en savoir un peu plus sur moi ! </h2><br/> <br/>
            <div className='text-xl text-start mb-2'><strong>Parcours Professionnel et Académique</strong></div> 
            J'ai commencé mon parcours dans ce domaine passionnant en 2022. Mon voyage académique et professionnel dans le monde du développement web est ponctué d'apprentissage continu et de croissance constante.
            <br/> <br/>
            <div className='text-xl text-start mb-2'><strong>Compétences Techniques</strong></div>
            Ma trousse à outils de développement est bien équipée avec une variété de langages et de frameworks. Je suis compétent en HTML, CSS, Bootstrap, Tailwind, React, Vue, Symfony, PHP, WordPress, Prestashop, Figma, Next.js, Photoshop, SASS, NextJS, et MySQL. Cette diversité me permet d'aborder les projets web sous différents angles.
            <br/> <br/>
            <div className='text-xl text-start mb-2'><strong>Objectifs Professionnels</strong></div> 
            Mon ambition professionnelle est de repousser mes limites dans le développement web et de parfaire continuellement mes connaissances dans divers langages de programmation. Je suis déterminé à rester à la pointe des tendances technologiques et à contribuer de manière significative au domaine du développement web.
            <br/> <br/>
            <div className='text-xl text-start mb-2'><strong>Intérêts Personnels et Hobbies</strong></div>
            En dehors du développement web, je suis un grand fan de football, supportant avec passion Manchester City depuis mon enfance. J'apprécie également les sorties entre amis, les voitures de sport, les jeux de stratégie et passer du temps en famille.
            <motion.div>
            <ol class="border-l-2 border-black-lo dark:border-white-lo mt-16">
                <li>
                    <div class="flex-start flex items-center">
                        <div
                        class="-ml-[9px] -mt-2 mr-3 flex h-4 w-4 items-center justify-center rounded-full"></div>
                    <h4 class="-mt-2 text-xl font-semibold">Certificat d'enseignement secondaire supérieur : CESS</h4>
                    </div>
                    <div class="mb-6 ml-6 pb-6">
                    <p class="mt-2 text-md">Septembre, 2021</p>
                    <p class="mb-4 mt-2">
                    J'ai obtenu mon CESS en technique de qualification section Électricien Automaticien. Au collège Saint-Augustin situé à Enghien dans le Hainaut.
                    </p>
                    </div>
                    </li>

                    <li>
                    <div class="flex-start flex items-center">
                    <div
                    class="-ml-[9px] -mt-2 mr-3 flex h-4 w-4 items-center justify-center rounded-full"></div>
                    <h4 class="-mt-2 text-xl font-semibold">Brevet d'enseignement supérieur : BES </h4>
                    </div>
                    <div class="mb-6 ml-6 pb-6">
                    <p class="mt-2 text-md">Septembre, 2022</p>
                    <p class="mb-4 mt-2">
                    J'ai entamé ma formation en développement web en 2022 dans un établissement d'enseignement à Marcq dans le Hainaut, avec l'objectif de la compléter d'ici fin 2024.
                    </p>
                    </div>
                </li>
            </ol>
            <div className="flex flex-wrap justify-center md:flex-row sm:flex-col items-center">
      <a href="https://github.com/LoickBck" className="linear flex flex-row items-center rounded-full bg-gradient-to-br from-blu-lo to-blue-lo pr-3 py-3 text-base font-medium text-white transition duration-200 hover:shadow-lg hover:shadow-blu-lo sm:mt-4 mr-10 pl-2">
        <FaGithub /> GitHub
      </a>
      <a href="https://www.linkedin.com/in/loick-buck-9a7b74273/?originalSubdomain=be" className="linear flex flex-row items-center rounded-full bg-gradient-to-br from-blu-lo to-blue-lo pr-3 py-3 text-base font-medium text-white transition duration-200 hover:shadow-lg hover:shadow-blu-lo sm:mt-4 mr-10 pl-2">
        <FaLinkedin /> LinkedIn
      </a>
      <a href="/CV%20-%20BUCK%20LOICK.pdf" download="CV - BUCK LOICK.pdf" className="linear flex flex-row items-center rounded-full bg-gradient-to-br from-blu-lo to-blue-lo pr-3 py-3 text-base font-medium text-white transition duration-200 hover:shadow-lg hover:shadow-blu-lo sm:mt-4 mr-10 pl-2">
        <FaDownload />CV
      </a>
      <Link to="/contact" className="linear flex flex-row items-center rounded-full bg-gradient-to-br from-blu-lo to-blue-lo pr-3 py-3 text-base font-medium text-white transition duration-200 hover:shadow-lg hover:shadow-blu-lo pl-2 sm:mt-4 mr-10">
        <IoMailOpenOutline /> Contact
      </Link>
    </div>
            </motion.div>
            </motion.div>
            <motion.div className='w-full mt-16 flex items-center justify-center'>
                <motion.img className='lg:w-6/12 sm:w-11/12 rounded-2xl'
                    src={HeroImage2}
                    alt="..."
                    initial='hidden'
                    animate='visible'
                    variants={imageVariants}
                    whileHover={{ scale: 1.1 }}
                />
            </motion.div>
        </motion.div>
    )
}

export default About;