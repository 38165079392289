import React from "react";
import { Carousel } from "@material-tailwind/react";
import { FaHtml5, FaCss3 ,FaGithub, FaFigma } from "react-icons/fa";
import { TbWorldWww } from "react-icons/tb";
import { Link } from 'react-router-dom';
import { DiSass } from "react-icons/di";

const Project1 = () => {
    return (
        <div className='project flex flex-col '>
            <div className='my-16'></div>
        <div className='grid grid-cols-1 place-items-center mt-10 xs:mb-[28rem] sm:mb-[44rem] lg:mx-24'>
            <div
        className="block rounded-lg w-10/12 h-72 stroke-2 stroke-blu-lo xs:mb-[22rem] sm:mb-[44rem] md:mb-[20rem] lg:mb-[20rem] xlg:mb-[20rem]">
          <Carousel
          className="rounded-t-lg h-[30rem]"
          navigation={({ setActiveIndex, activeIndex, length }) => (
          <div className="absolute bottom-4 left-2/4 z-50 flex -translate-x-2/4 gap-2">
            {new Array(length).fill("").map((_, i) => (
              <span
                key={i}
                className={`block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${
                  activeIndex === i ? "w-8 bg-white" : "w-4 bg-white/50"
                }`}
                onClick={() => setActiveIndex(i)}
              />
            ))}
          </div>
        )}
        >
        <img
          src="https://i.postimg.cc/1z7jz635/tropical-heatwave-high-resolution-logo-transparent.png"
          alt="Tropical Heatwave Logo"
          className="h-full w-full object-scale-down pt-4 bg-blac-lo"
        />
        <img
          src="https://i.postimg.cc/1XN95s04/Capture-d-cran-2023-12-28-034809.png"
          alt="Screenshot du site Tropical Heatwave 1"
          className="h-full w-full object-cover"
        />
        <img
          src="https://i.postimg.cc/85XRgBwN/Capture-d-cran-2023-12-28-034925.png"
          alt="Screenshot du site Tropical Heatwave 2"
          className="h-full w-full object-cover"
        />
        </Carousel>
        <div className="p-6 rounded-b-lg bg-blac-lo lg:mb-64">
          <h5
            className="mb-2 text-2xl font-semibold text-white text-center">
            Tropical Heatwave
            <div className="h-[2px] xlg:w-1/4 lg:w-1/3 sm:w-1/2 mx-auto mt-4 place-self-center bg-blu-lo"></div>
          </h5>
          
          <div className="flex flex-row items-center justify-center">
          <FaHtml5 className='my-5  text-orange-900' size={65} />
          <FaCss3 className='my-5  text-blue-300' size={60} />
          <DiSass className='my-5  text-pink-400' size={70} />
          </div>
          <p className="mb-8 text-center text-white">
          Découvrez Tropical Heatwave, mon tout premier projet en tant que développeur web junior, créé avec passion et dévouement dans le cadre de mon examen de fin de première année. Ce site en HTML/Sass incarne l'énergie vibrante d'un festival musical estival. Naviguez à travers une interface captivante, savourez la simplicité de la conception, et plongez dans une expérience immersive conçue pour capturer l'essence même de la musique en plein air. <br /> <br /> Tropical Heatwave, une réalisation significative de ma première année en tant que développeur web, où la créativité rencontre la technologie pour créer une expérience mémorable.
          </p>
          <p className="mb-8 text-end text-white">Les liens vers le site , design Figma et le git du projet se trouvent juste en dessous</p>
          <div className="flex flex-col xs:flex-row items-center mx-4">
            <Link
            to='http://tropical-heatwave.buckloick.com/index.html'
            className="mr-4 linear flex flex-row items-center rounded-full bg-gradient-to-br from-blu-lo to-blue-lo px-3 py-3 text-base font-medium text-white transition duration-200 hover:shadow-lg hover:shadow-blu-lo"
            data-ripple-light
            >
            <TbWorldWww />
            Voir le projet
            </Link>
            <Link
            to='https://www.figma.com/file/PIwPYQ0HU83UR5dKkQ5q6P/Tropical-Heatwave?type=design&node-id=0%3A1&mode=design&t=om9bbVOMrPXX8bDI-1'
            className="mr-4 linear flex flex-row items-center rounded-full bg-gradient-to-br from-blu-lo to-blue-lo pr-3 py-3 text-base font-medium text-white transition duration-200 hover:shadow-lg hover:shadow-blu-lo sm:mt-4 xs:mt-0"
            data-ripple-light
            >
            <FaFigma />
            Figma
            </Link>
            <Link
            to='https://github.com/LoickBck/TropicalHeatwave'
            className="mr-4 linear flex flex-row items-center rounded-full bg-gradient-to-br from-blu-lo to-blue-lo pr-3 py-3 text-base font-medium text-white transition duration-200 hover:shadow-lg hover:shadow-blu-lo sm:mt-4 xs:mt-0"
            data-ripple-light
            >
            <FaGithub />
            GitHub
            </Link>
            </div>
        </div>
        </div>
        </div>
    </div>
    )
}

export default Project1;